@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/border-width';
@import '../../assets/scss/settings/colors';

.carousel {
  &-arrow {
    margin: 0 1.5rem;
    width: 1rem;
  }

  &-img {
    height: 130px;
    width: 200px;
    flex: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &-wrapper {
    position: relative;
    height: 130px;
    width: 200px;
  }

  &-slider {
    transition: left 0.5s;
    display: flex;
    position: absolute;
    left: 0;
  }

  &-bullet {
    border-radius: $border-circle;
    border: $border-width-sm solid $color-black;
    height: 0.5rem;
    width: 0.5rem;
    margin: 0.25rem;

    &-selected {
      background-color: $color-black;
    }
  }
}

@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/border-width';
@import '../../assets/scss/settings/colors';

.card {
  background-color: $color-white;
  border-radius: $border-radius-5;
  padding: 1rem;
  height: 100%;

  &-shadow {
    box-shadow: 0px 0px 3px rgba($color-mine-shaft, 0.15);
  }

  &-column {
    height: calc(100% - 3rem);
    overflow-y: auto;

    .no-data {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 1.5rem;
      text-align: center;

      svg {
        height: 2.5rem;
        margin-bottom: 1rem;
        width: 2.5rem;
      }

      p {
        color: $color-nobel;
      }
    }

    &-wrapper {
      height: calc(100vh - 16.5rem);
      transition: height 0.2s ease-in-out;
      overflow: hidden;
      position: relative;

      &-compressed {
        height: calc(100vh - 16.5rem - 5.625rem);
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      margin: 0.25rem 0 1rem;
    }

    &-container {
      display: flex;
      height: 100%;
      transition: transform 0.5s ease-in-out;
      width: 100%;

      > div {
        min-width: calc(25% - 0.75rem);

        &:not(:first-child) {
          margin-left: 1rem;
        }
      }

      &.animated {
        transform: translateX(calc(-25% - 4px));
      }
    }

    &-item {
      align-items: center;
      background-color: $color-white;
      border: $border-width-sm solid $color-silver;
      border-radius: $border-radius-5;
      cursor: pointer;
      display: flex;
      padding: 1rem;
      text-align: left;
      width: 100%;

      &-wrapper {
        position: relative;

        .card-column-item {
          &:not(:first-child) {
            padding-top: 1.55rem;
          }
        }

        &:first-child {
          .card-column-item {
            &:not(:first-child) {
              margin-top: 0.5rem;
            }
          }
        }

        &:not(:first-child) {
          .card-column-item {
            &:not(:first-child) {
              margin-top: 0.75rem;
            }

            &:first-child {
              margin-top: 0.25rem;
            }
          }
        }

        &:not(:nth-child(n + 4)) {
          .action-list {
            top: 2rem;
          }
        }

        &:nth-child(n + 4) {
          .action-list {
            top: auto;
            bottom: 2rem;
          }
        }
      }

      &-selected {
        background-color: rgba($color-turquoise, 0.15);
        border-color: transparent;
      }

      &-info {
        flex: 1;
        margin-left: 0.75rem;
        overflow-x: hidden;
        padding-right: 2rem;

        & * {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &[disabled] {
        color: inherit;
      }
    }

    .actions {
      cursor: pointer;
      display: inherit;
      position: absolute;
      right: 1rem;
      top: calc(50% - 1rem);

      &:hover {
        > .action-list {
          display: block;
        }
      }
    }

    .action-list {
      display: none;
      min-width: 9rem;
      position: absolute;
      right: 0;
      transition: opacity 0.3s ease-in-out;
      z-index: 100;

      li {
        color: $color-mine-shaft;
        cursor: pointer;
        display: flex;

        &:first-child {
          button {
            border-radius: $border-radius-5 $border-radius-5 $border-radius-0 $border-radius-0;
          }
        }

        &:last-child {
          button {
            border-radius: $border-radius-0 $border-radius-0 $border-radius-5 $border-radius-5;
          }
        }

        button {
          border-radius: $border-radius-0;
          justify-content: flex-start;
          padding: 0.675rem 1rem;
          width: 100%;

          &:not(:disabled):hover {
            background-color: $color-wild-sand;
          }
        }
      }
    }
  }
}

$border-radius-0: 0px;
$border-radius-1: 1px;
$border-radius-2: 2px;
$border-radius-3: 3px;
$border-radius-4: 4px;
$border-radius-5: 5px;
$border-radius-6: 6px;
$border-radius-8: 8px;
$border-radius-10: 10px;
$border-rounded: 50px;
$border-circle: 50%;

@import '../../assets/scss/settings/border-radius'; // utilizar?
@import '../../assets/scss/settings/border-width'; // utilizar?
@import '../../assets/scss/settings/colors'; // utilizar?

.progress {
  margin: 2rem 0;
  background-color: $color-wild-sand;
  border-radius: 1rem;

  &-fill {
    width: 0;
    height: 100%;
    border-radius: 1rem;
    background-color: $primary;
    transition: width 0.2s;
  }
  &-text {
    margin-top: 1rem;
    position: absolute;
  }
}

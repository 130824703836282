.info-action {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em;
}

.device-property {
  border-bottom-style: solid;
  border-bottom-width: 0.01em;
  border-bottom-color: #dbdbdb;
}

.subtitle-user-quota {
  margin-top: 10px;
  font-size: 13px !important;
}

.btn-find-speedtest {
  background-color: #4a4d4e !important;
  color: #fff !important;
  padding: 0px 45px !important;
}

.btn-clear-speedtest {
  background-color: #fff !important;
  color: #4a4d4e !important;
  border: 1px solid #4a4d4e !important;
  padding: 0px 45px !important;
}

.custom-count {
  font-weight: normal !important;
  font-size: 3rem !important;
}

.div-counter-speedtest {
  padding-top: 25px !important;
  padding-bottom: 40px !important;
  font-size: 3.5rem !important;
}

.loader-wrapper {
  height: 19.9vh !important;
}

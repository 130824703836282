@import '../../../../../../../../assets/scss/settings/colors';

.wifi-display-flex {
  display: flex;
}

.wifi-item {
  margin-right: 4rem;
}

.wifi-item-title {
  font-weight: bold;
}

.wifi-divider {
  content: '';
  margin-top: 15px;
  padding-bottom: 5px;
  border-top: 1px solid $color-wild-sand;
}

.custom-tooltip {
  background-color: $color-white;
  border: 1px solid $color-nobel;
  padding: 5px;
}
.table {
  .has-chevron {
    a {
      display: inline-block;
      position: relative;

      & i {
        position: absolute;
      }
    }

    .mdi-chevron-right {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .has-checkbox {
    width: 100%;

    & :first-child {
      & input[type='checkbox'] {
        margin-right: 10px;
      }
    }
  }

  &.is-vcentered {
    td {
      vertical-align: middle;
    }
  }
}

@media screen and (min-width: $widescreen) {
  .table {
    .has-chevron {
      .mdi-chevron-right {
        margin-top: -2px;
      }
    }
  }
}

@import '../../../../../../assets/scss/settings/border-radius';
@import '../../../../../../assets/scss/settings/colors';
@import '../../../../../../assets/scss/tools/placeholders';
@import '../../../../../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.rtf-side-menu {
  &-container {
    height: 31rem;
    background: white;
    padding: 1rem;
    border-radius: $border-radius-5;
  }

  &-subcategory {
    cursor: pointer;

    &-active {
      color: $color-white;
      background-color: $color-red;
      font-weight: bold;
      cursor: default;
      border-radius: $border-radius-5;

      &::before {
        content: '';
        background-color: $color-maroon;
        position: absolute;
        width: 0.25rem;
        height: 50%;
        left: -1px;
      }
    }

    &:hover {
      font-weight: bold;
    }
  }
}

.image {
  &-figure-box-overview {
    align-items: center;
    display: flex;
    height: 23rem;

    img {
      height: 100%;
      width: auto;
      margin: auto;
    }
  }
}

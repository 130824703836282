@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/colors';

.badge {
  background-color: $color-silver;
  border-radius: $border-rounded;
  font-family: 'VodafoneRegularBold', Arial, sans-serif;
  font-size: 0.75rem;
  height: 1.375rem;
  left: 1rem;
  letter-spacing: 1px;
  top: -0.5rem;
  padding: 0.125rem 0.75rem;

  &-float {
    position: absolute;
  }

  &-action {
    align-items: center;
    background-color: rgba($color-mine-shaft, 0.25);
    border-radius: $border-circle;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-left: 0.25rem;

    &:hover {
      background-color: rgba($color-mine-shaft, 0.35);
    }

    svg {
      stroke: $color-white;
    }
  }

  span.badge-action {
    pointer-events: none;
  }

  &-error {
    bottom: 100%;
    left: 0;
    position: absolute;

    .toaster {
      margin: 0 0 8px;
      min-width: unset;
      width: calc(25vw - 2.5rem);
    }
  }

  &.has-button {
    padding-right: 0.25rem;
  }

  &-info {
    background-color: $color-turquoise;
    color: $color-white;
  }

  &-danger {
    background-color: $color-red;
    color: $color-white;
  }
}

.datetimepicker {
  .datepicker-body {
    .datepicker-dates {
      .datepicker-days {
        .datepicker-date {
          .date-item.is-active.is-today {
            &:hover {
              background-color: $color-red !important;
              color: #ffffff !important;
            }
          }

          &.datepicker-range {
            .date-item {
              &:hover {
                color: $primary !important;
              }

              &.is-today {
                color: $grey-light !important;
              }
            }
          }
        }
      }
    }
  }

  .timepicker {
    input {
      min-width: 100%;
    }
  }
  &-footer {
    button {
      &.is-text {
        font-size: 1rem;
      }

      &:first-child {
        color: $primary !important; //To overwrite bulma css
      }
    }
  }
}

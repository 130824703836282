* {
  margin: 0;
  padding: 0;
}

html {
  overflow: auto;
}

body {
  color: $color-mine-shaft;
  font-size: $font-size-base;
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  letter-spacing: 0.75px;
}

p {
  line-height: 1.25rem;
}

button {
  font-size: 100%;
}

svg {
  pointer-events: none;
}

.notification {
  border-radius: $border-radius-6;
  font-size: 16px;
  margin-bottom: 1.2rem;
  max-height: 150px;
  max-width: 800px;
  min-width: 500px;
  overflow-y: auto;
  text-align: left;

  &.is-danger {
    background-color: $color-white;
    border: $border-width-sm solid $danger;
    color: $color-black;
  }

  &.is-warning {
    background-color: $color-lemon-yellow;
  }

  &.is-success {
    background-color: $color-ui-green;
  }
}

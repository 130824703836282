.flex {
  &-zero {
    flex: 0;
  }

  &-column {
    flex-direction: column;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

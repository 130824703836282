.tile {
  &:not(.is-child) {
    padding-left: 0.75rem;
  }

  &-vcentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.is-flex-none {
    flex: none;
  }

  &.is-ancestor {
    padding-left: 0;
  }

  .box {
    border-radius: $border-radius-10;
  }
}

// Primary colours
$color-red: #e60000;
$color-mine-shaft: #333333;
$color-white: #ffffff;

// Secondary colours
$color-lemon-yellow: #fecb00;
$color-fresh-orange: #eb9700;
$color-spring-green: #a8b400;
$color-seance: #9c2aa0;
$colot-aubergine: #5e2750;
$color-turquoise: #007c92;
$color-dark-turquoise: #015261;
$color-aqua-blue: #00b0ca;
$color-light-turquoise: #d1f1f5;

//Digital
$color-ui-green: #009900;
$color-maroon: #990000;

//Monochrome
$color-black: #000000;
$color-abbey: #4a4d4e;
$color-dove-gray: #666666;
$color-dusty-gray: #999999;
$color-nobel: #b5b5b5;
$color-aluminium: #ebebeb;
$color-silver: #cccccc;
$color-wild-sand: #f4f4f4;
$color-alabaster: #f7f7f7;

$color-atlo: #dbdbdb;
$color-mercury: #e7e7e7;
$color-concrete: #f2f2f2;

//Main colors
$primary: $color-red;
$secondary: $color-dove-gray;

@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/border-width';
@import '../../assets/scss/settings/colors';
@import '../../assets/scss/settings/z-index';

.notifications {
  text-align: left;

  &-container {
    animation: expand 0.5s forwards;
    position: absolute;
  }

  &-number {
    background-color: $color-red;
    border-radius: $border-circle;
    bottom: 0;
    color: $color-white;
    font-size: 14px;
    height: 1rem;
    position: absolute;
    right: -0.25rem;
    width: 1rem;
  }

  &-container {
    border-radius: $border-radius-5;
    box-shadow: 0 1px 5px rgba($color-mine-shaft, 0.15);
    color: $color-mine-shaft;
    overflow: hidden auto;
    right: 1rem;
    top: 6rem;
    width: 30rem;
    z-index: $zi-content;

    .card {
      border-radius: $border-radius-0;
    }
  }

  &-title {
    padding: 1.5rem 1rem 0;
  }

  &-item {
    border-left: $border-width-md solid transparent;
    border-bottom: $border-width-sm solid $color-wild-sand;
    cursor: pointer;
    flex-basis: 100%;
    padding: 1rem 0.875rem 1rem 1rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: $color-wild-sand;
    }

    p {
      margin-top: 0.5rem;

      &:last-of-type {
        margin-bottom: 0.75rem;
      }
    }

    &.is-highlighted {
      background-color: rgba($color-turquoise, 0.05);
      border-left-color: $color-turquoise;

      &:hover {
        background-color: rgba($color-turquoise, 0.1);
      }
      &.success {
        border-left-color: $color-ui-green;
      }
      &.error {
        border-left-color: $color-red;
      }
      &.warning {
        border-left-color: $color-lemon-yellow;
      }
    }
  }

  &-loading {
    box-shadow: 0 -1px 3px rgba($color-mine-shaft, 0.15);
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100%;
  }

  &-empty {
    margin: 0 auto 4.5rem;
    text-align: center;
    width: 350px;

    svg {
      max-width: 250px;
      height: auto;
    }
  }
}

.ReactVirtualized__Table__headerColumn {
  text-transform: capitalize;
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid $color-atlo;
  padding: 0.5em 0;
  vertical-align: top;
}

.ReactVirtualized__Table__row:nth-child(even) {
  background-color: $color-concrete;
}

@import '../../../../../assets/scss/settings/colors';

.timeline {
  &-button {
    background-color: $color-red;
    border: solid 0.1rem $color-red;
    border-radius: 7rem;
    width: 1.5rem;
    height: 1.5rem;
    margin: 1.25rem 0 -0.6rem -0.8rem;
    z-index: 1;
    position: absolute;
    cursor: pointer;
    right: -0.75rem;

    svg {
      transition: transform 0.6s;
    }
  }

  &-transition {
    transition: all 1s;
  }

  &-collapsed {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }

  &-expanded {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
  }

  &-subtitle {
    white-space: nowrap;
  }

  &-square {
    width: 15px;
    height: 15px;
    border-radius: 0 4px;
    margin-right: 0.5rem;

    &.successful {
      background: $color-turquoise;
    }
    &.partial {
      background: url('../../../../../assets/svg/timeline-pattern.svg');
      background-size: 1rem 1rem;
    }

    &.planned {
      background: $color-light-turquoise;
    }

    &.failed {
      background: $color-dark-turquoise;
    }
  }

  &-container {
    &-outer {
      overflow-x: auto;
      width: 100%;
      height: auto;
    }
  }
}

$font-path: '../fonts/';

$font-family-vodafone-light: 'VodafoneLight';
$font-family-vodafone-regular: 'VodafoneRegular';
$font-family-vodafone-regular-bold: 'VodafoneRegularBold';

$font-family-sans-serif: $font-family-vodafone-regular, Helvetica, Arial, sans-serif;

$font-list-vodafone: ($font-family-vodafone-light, $font-family-vodafone-light),
  ($font-family-vodafone-regular, $font-family-vodafone-regular),
  ($font-family-vodafone-regular-bold, $font-family-vodafone-regular-bold);

@each $vf-font-family, $vf-font-path in $font-list-vodafone {
  @font-face {
    font-family: $vf-font-family;
    src: url($font-path+$vf-font-path+'.ttf');
    src: local('Vodafone'), local('Vodafone'),
      url($font-path+$vf-font-path+'.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }
}

@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/border-width';
@import '../../assets/scss/settings/colors';
@import '../../assets/scss/settings/z-index';
@import '../../assets/scss/base/bulma-variables';

.toaster {
  background-color: $color-white;
  border: $border-width-sm solid transparent;
  border-radius: $border-radius-10;
  box-shadow: 0 2px 8px rgba($color-black, 0.15);
  color: $color-mine-shaft;
  font-size: 1rem;
  margin: 1.25rem auto;
  max-width: 45rem;
  min-width: 20rem;
  transition: transform 0.6s ease-in-out;

  &-out {
    animation: slideRight 0.4s forwards;
  }

  &-success {
    border-color: $success;

    .toaster-media {
      background-color: $success;
    }
  }

  &-info {
    border-color: $info;

    .toaster-media {
      background-color: $info;
    }
  }

  &-danger {
    border-color: $danger;

    .toaster-media {
      background-color: $danger;
    }
  }

  &-container {
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: fixed;
    width: 100%;
    z-index: $zi-spinner;
  }

  &-media {
    border-right: none;
    padding: 1rem;
    position: relative;
    border-bottom-left-radius: $border-radius-8;
    border-top-left-radius: $border-radius-8;

    .icon {
      height: 2rem;
      width: 2rem;
    }
  }

  .caption {
    display: flex;
    position: relative;

    &-media,
    &-text {
      display: flex;
      flex-direction: column;
    }

    &-media {
      justify-content: center;
    }

    &-text {
      padding: 0.75rem 1rem;
      width: 100%;

      strong {
        display: block;
        font-size: 1.15rem;
        margin-bottom: 0.25rem;
      }

      p {
        max-height: 5.5rem;
        overflow-y: auto;
        word-break: break-word;
      }
    }

    &-close {
      background-color: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 0.75rem;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.panel {
  margin-bottom: 2rem;
  padding: 0 1.75rem;

  &-item {
    background-color: $color-white;
    border-radius: $border-radius-8;
    box-shadow: 0 2px 4px rgba($color-black, 0.2);
    margin: 0 0 0.75rem;
    min-height: 6rem;
    padding: 1.5rem 1.5rem 1.5rem 1rem;

    &:last-child {
      margin: 0;
    }

    &-status {
      border-left: $border-width-xl solid;
      border-radius: $border-radius-8;

      &.is-danger {
        border-left-color: $danger;
      }

      &.is-info {
        border-left-color: $info;
      }

      &.is-success {
        border-left-color: $success;
      }
    }

    &-grey {
      background-color: $color-alabaster;
    }
  }
}

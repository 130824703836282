.icon {
  &-button {
    background: none;
    border: none;
    cursor: pointer;
    color: $color-abbey;
    margin: 0.2em;

    &:hover {
      background: none;
      color: $grey-light;
    }

    &:focus {
      outline: 0;
    }
  }

  &-svg {
    fill: transparent;
    stroke: $color-mine-shaft;
    stroke-width: 10;

    &-fill {
      fill: $color-mine-shaft;
    }

    &.is-shape {
      stroke-width: 1px;
    }
  }

  &-vcentered {
    vertical-align: middle;
  }

  &-white {
    stroke: $color-white;

    &-fill {
      fill: $color-white;
    }
  }

  &-highlighted {
    stroke: $color-turquoise;
  }

  &-faded {
    stroke: $color-nobel;
  }

  &-hovered {
    svg {
      stroke: $color-dove-gray;
    }

    &:hover svg {
      stroke: $color-mine-shaft;
    }
  }

  &-success {
    stroke: $success;
  }

  &-info {
    stroke: $info;
  }

  &-danger {
    stroke: $danger;
  }

  &-error {
    fill: $color-red;
    stroke: $color-white;
    margin-right: 0.5rem;
  }

  &-180 {
    transform: rotate(180deg);
  }

  &-tiny {
    height: 0.75rem;
    width: 0.75rem;
  }

  &-xxx-small {
    height: 1.125rem;
    width: 1.125rem;
  }

  &-xx-small {
    height: 1.25rem;
    width: 1.25rem;
  }

  &-x-small {
    height: 1.5rem;
    width: 1.5rem;
  }

  &-small {
    height: 1.75rem;
    width: 1.75rem;
  }

  &-medium {
    height: 2rem;
    width: 2rem;
  }

  &-large {
    height: 2.25rem;
    width: 2.25rem;
  }

  &-spinner {
    animation: load8 1.5s infinite linear;
  }
}

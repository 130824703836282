@import '../../assets/scss/settings/colors';
@import '../../assets/scss/settings/border-radius';

.toggle-switch {
  color: $color-white;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: 4.25rem;

  input {
    display: none;
  }

  &-label {
    border-radius: $border-rounded;
    cursor: pointer;
    display: block;
    overflow: hidden;
  }

  &-inner {
    display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;

    &:before,
    &:after {
      display: block;
      float: left;
      line-height: 2rem;
      width: 50%;
    }

    &:before {
      background-color: $color-turquoise;
      content: attr(data-yes);
      padding-left: 0.75rem;
    }
  }

  &-disabled {
    background-color: $color-dove-gray;
  }

  &-inner:after {
    content: attr(data-no);
    padding-right: 0.75rem;
    background-color: $color-dove-gray;
    text-align: right;
  }

  &-switch {
    background-color: $color-white;
    border-radius: $border-rounded;
    bottom: 0;
    margin: 5px;
    position: absolute;
    right: 2.125rem;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 1.5rem;
  }

  input:checked + &-label {
    .toggle-switch {
      &-inner {
        margin-left: 0;
      }

      &-switch {
        right: 0;
      }
    }
  }
}

.info {
  background-color: $color-white;
  border-radius: $border-rounded;
  display: flex;
  padding: 0 1rem;

  button {
    &::before {
      background-color: $color-silver;
      content: '';
      display: inline-block;
      height: 1.5rem;
      width: 1px;
      margin: 0.5rem 0.75rem;
    }
  }
}

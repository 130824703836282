@import '../settings/colors.scss';

.nav {
  position: sticky;
  top: 0;
  left: 0;
  height: 101px;
  z-index: 9999;

  &-first-level {
    width: 100%;
    background-color: $color-red;
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0;

    &-content {
      padding: 0 1.5rem;
      display: flex;
      position: relative;
      align-items: center;

      &:hover > .dropdown-options {
        visibility: visible;
      }

      &:last-child {
        border-left: solid 1px $color-white;
      }

      p {
        color: $color-white;
        font-size: 14px;
      }
    }
  }

  &-second-level {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $color-aluminium;
    width: 100%;
    background: $color-white;
    justify-content: space-between;

    .navbar {
      position: static;
      flex: 1;
      margin-left: 2rem;

      &-icon {
        width: 3rem;
        height: 3rem;
        margin: 0.75rem 1.25rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .icons {
      &-with-text {
        margin: 0 1rem;
        cursor: pointer;

        h6 {
          font-size: 14px;
          margin-bottom: -4px;
          color: $color-dove-gray;
          text-decoration: none;
        }

        &:hover {
          h6 {
            color: $color-mine-shaft;
          }

          > .dropdown-options {
            visibility: visible;
          }
        }
      }
    }
  }

  .dropdown-options {
    position: absolute;
    background: $color-white;
    border-radius: 4px;
    visibility: hidden;
    z-index: $zi-content;
    @extend %tile-boxshadow;
    top: 100%;
    right: 0;

    &-splitted {
      font-size: 14px;
      border-top: solid 1px $color-aluminium;
      border-radius: 0;
    }

    .dropdown-item:first-child:hover {
      border-radius: 4px;
    }

    .dropdown-link {
      text-align: center;
      white-space: nowrap;
      width: 100%;
      font-size: 14px;
      line-height: 1.25rem;
    }

    .button-action,
    .button-link {
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
  }

  &-third-level {
    width: 100vw;
    background-color: $color-white;
    top: 101px;
    right: 0;
    z-index: $zi-content;
    position: absolute;
    max-height: 0;
    transition: max-height 0.3s;

    ul {
      padding-left: 7.5rem;
      opacity: 0;
      transition: opacity 0.4s;
    }

    &.on-hover {
      max-height: 80px;
      ul {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .navbar {
    display: none;
  }
}

//This div is meant to increase specificity and overwrite bulma
div {
  input.input,
  .select select {
    border-color: $color-dusty-gray;
    border-radius: $border-radius-6;
    color: $color-mine-shaft;
    height: auto;
    line-height: 1.5rem;
    padding: 0.45rem 0.65rem;

    &::placeholder {
      color: $color-dusty-gray;
    }

    &:active,
    &:focus,
    &:hover {
      border-color: $color-dove-gray;
      box-shadow: none;
    }

    &[disabled] {
      background-color: $color-aluminium;
      border-color: $color-silver;
      color: $color-mine-shaft;
      opacity: 1;
    }

    + .error-message {
      svg {
        display: inline-block;
        margin-right: 1rem;
      }
    }

    &.has-icon {
      padding-right: 2.5rem;
    }
  }

  .select {
    select {
      font-size: 1rem;
      min-width: 11rem;

      &::-webkit-scrollbar {
        height: 100%;
        width: 0.625rem;

        &-thumb {
          background-color: $color-nobel;
          border-radius: $border-radius-6;
        }

        &-track {
          background-color: $color-aluminium;
          border-radius: $border-radius-0 $border-radius-6 $border-radius-6 $border-radius-0;
        }
      }
    }

    &:not(.is-multiple) {
      height: auto;
    }

    &:not(.is-multiple):not(.is-loading)::after {
      border: $border-width-sm solid $color-mine-shaft;
      border-right: $border-width-null;
      border-top: $border-width-null;
      height: 0.9rem;
      margin-top: -0.6rem;
      right: 1rem;
      width: 0.9rem;
    }
  }
}

.radio .is-checkradio[type='radio'] {
  + label {
    &:before,
    &:after {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  //The importants below are meant to overwrite another important from bulma framework.
  &:checked,
  &:hover:not(:disabled) {
    + label {
      &:before {
        border-color: $color-turquoise !important;
      }

      &:after {
        background-color: $color-turquoise !important;
      }
    }
  }

  &:checked[disabled] {
    + label {
      opacity: 1;

      &:before {
        border-color: $color-silver !important;
      }

      &:after {
        background-color: $color-silver !important;
      }
    }
  }
}

.checkbox {
  input[type='checkbox'] {
    opacity: 0;
    height: 1.25rem;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  label {
    position: relative;
    display: block;
    padding-left: 2rem;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      transition: transform ease 0.25s;
    }

    &:before {
      border: 1px solid $color-dusty-gray;
      border-radius: $border-radius-3;
      height: 1.25rem;
      left: 0;
      top: 0;
      width: 1.25rem;
    }

    &:after {
      display: block;
      height: 6px;
      left: 4px;
      top: 6px;
      transform: rotate(-45deg) scale(0);
      width: 12px;
    }
  }

  :checked {
    ~ label {
      &:before {
        background-color: $color-turquoise;
        border: $border-width-sm solid rgba($color-black, 0.2);
      }

      &:after {
        border-bottom: $border-width-md solid $color-white;
        border-left: $border-width-md solid $color-white;
        transform: rotate(-45deg) scale(1);
      }
    }

    &[disabled] {
      ~ label {
        &:after {
          border-color: rgba($color-mine-shaft, 0.25);
        }
      }
    }
  }

  [disabled] {
    ~ label {
      &:before {
        background-color: $color-silver;
        border: $border-width-sm solid $color-silver;
      }
    }
  }
}

.label {
  font-weight: inherit;
}

@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/colors';
@import '../../assets/scss/tools/placeholders';
@import '../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.search {
  &-container {
    border-radius: $border-radius-10;
    padding: 1rem 1.5rem;
  }
  input.mr-0{
    margin-right: 0px;
  }

  input,
  .select select {
    font-weight: 100;
    min-width: 100%;
  }

  input,
  .select,
  .select select {
    width: 100%;
  }

  .in-input {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
  }

  .double-input{
    width: calc(50% - 1.5rem);
    margin-right: 1.5rem;
    display: inline-flex;
  }

  .double-input + .in-input {
    right: 2.25rem;
  }

  .date-input {
    font-weight: 100;
    letter-spacing: 1px;
  }

  .rc-slider {
    &-dot {
      bottom: -4px;
      height: 12px;
      width: 12px;

      &-active {
        border-color: $primary;
      }
    }

    &-handle {
      background-color: $primary;
      border-color: $primary;
      height: 18px;
      margin-top: -7px;
      width: 18px;
    }

    &-track {
      background-color: $primary;
    }
  }

  .has-filters {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    .has-icon svg {
      transform: rotate(180deg);

      &.is-active {
        border-color: $primary;

        svg {
          fill: $primary;
        }
      }
    }

    + .filters {
      max-height: 20vh;
      opacity: 1;
    }
  }

  .filters {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.4s ease;
  }

  .slider {
    padding-bottom: 2rem;
    padding-right: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .search {
    .double-input{
      width: calc(50% - .75rem);
      margin-right: .75rem;
      display: inline-flex;
    }

    .in-input{
      right: 2rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .search {
    .button {
      width: calc(50% - 0.75rem);

      &:first-child {
        margin-right: 0.75rem;
      }

      &:last-child {
        margin-left: 0.75rem;
      }
    }
  }
}

@media screen and (min-width: 867px) {
  .search {
    input,
    .select select {
      min-width: 220px;
    }
    .min-input-width {
      min-width: 86px;
      width: 87px;
      margin-right: 1.5rem;
    }
  }
}

@media screen and (min-width: $tablet) and (max-width: 867px) {
  .search {
    input,
    .select select {
      min-width: 125px;
      width: 125px;
    }
  }
}

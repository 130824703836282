.text {
  font-family: $font-family-vodafone-regular;
  font-weight: $font-weight-base;

  &:not(span) {
    display: block;
    line-height: $line-height-base;
    margin: 0;
  }

  &.is {
    &-h1 {
      font-size: 56px;
      line-height: 62px;
    }

    &-h2 {
      font-size: 42px;
      line-height: 48px;
    }

    &-h3 {
      font-size: 32px;
      line-height: 40px;
    }

    &-h4 {
      font-size: 24px;
      line-height: 30px;
    }

    &-h5 {
      font-size: 20px;
      line-height: 28px;
    }

    &-h6 {
      font-size: 16px;
    }

    &-underline {
      text-decoration: underline;

      &-none {
        text-decoration: none;
      }
    }

    &-bold {
      font-family: $font-family-vodafone-regular-bold;
    }

    &-light {
      font-family: $font-family-vodafone-light;
    }

    &-wrap {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    &-size {
      &-normal {
        font-size: 1rem;
      }

      &-sm {
        font-size: 14px;
        line-height: 28px;
      }

      &-small {
        font-size: 0.75rem;
      }
    }

    &-highlighted {
      color: $color-turquoise;
    }

    &-danger {
      color: $primary;
    }

    &-dark {
      color: $color-mine-shaft;
    }

    &-darkgrey {
      color: $color-abbey;
    }

    &-grey {
      color: $color-nobel;
    }
  }

  &-align {
    &-center {
      text-align: center;
    }

    &-end {
      text-align: end;
    }

    &-initial {
      text-align: initial;
    }
  }
}

.footer-style {
  background-color: $color-wild-sand;
  border-top: $border-radius-2 solid $color-mercury;
  bottom: 0;
  font-size: 10px;
  height: 25px;
  left: 0;
  padding: 4px 12px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 5;
}

@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/colors';
@import '../../assets/scss/tools/placeholders';
@import '../../assets/scss/settings/border-width';
@import '../../assets/scss/helpers/align';

.search-records-per-page {
  padding: 0.5rem 0.5rem 0rem;
  flex-grow: 1;
  text-align: right;

  .current-pagesize {
    color: #4a4a4a;
    position: relative;
    display: inline-block;
    border-bottom: 1px solid black;
    width: 3.5rem;
    margin: 0.5rem 0.5rem 0rem;
    text-align: left;
    user-select: none;

    &:hover .dropdown-options {
      visibility: visible;
    }
  }

  .icon-svg {
    float: right;
    margin-top: 0.4rem;
    margin-right: 0.1rem;

    .dropdown-options {
      visibility: visible;
    }
  }

  .dropdown-options {
    position: absolute;
    background: $color-white;
    border-radius: 4px;
    visibility: hidden;
    @extend %tile-boxshadow;
    top: 1.5rem;
    right: 0rem;
    z-index: 9999;

    .dropdown-item:first-child:hover {
      border-radius: 4px;
    }

    .button-action,
    .button-link {
      padding: 0.5rem 3rem 0.5rem 1rem;
    }
  }

  .active-item {
    background: red;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .search-records-per-page {
    display: inline-block;
    float: right;
  }
}

.in-input {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.row-container {
  .column {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.anonymizer-field {
  word-break: break-all;
}

.input-container {
  position: relative
}
@import '../../../../../../../../assets/scss/settings/colors';

.dvbc-header {
  display: flex !important;
  flex-direction: column;
}

.dvbc-message {
  display: flex;
  background-color: whitesmoke;
}

.alert {
  margin-right:.5rem !important;
  margin-bottom: 0 !important;
  color: $color-red;
}

.message {
  font-size: 1rem !important;
  font-weight: bold;
}
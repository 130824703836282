@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/colors';
@import '../../assets/scss/tools/placeholders';
@import '../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.side-menu {
  &-container {
    height: 31rem;
    background: white;
    padding: 0.75rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba($color-black, 0.2);

    a {
      display: flex;
      align-items: center;

      svg {
        height: 1rem;
        width: 1rem;
        margin-left: 5px;
      }

      &:hover {
        svg {
          fill: white;
        }
      }
    }
  }

  &-category {
    color: $color-mine-shaft;
    font-family: 'VodafoneRegularBold';
    background: none;
    border: none;
    cursor: pointer;

  }

  &-category:hover {
    background: $color-red;

    & > span {
      color: white;
    }

    &:first-child {
      border-radius: 10px 10px 0 0;
    }
  }

  &-category-active {
    background: $color-red;

    span {
      color: white;
    }

    &:first-child {
      border-radius: 10px 10px 0 0;
    }
  }

  &-subcategory {
    cursor: pointer;

    &-active {
      color: $color-red;

      &::before {
        content: '';
        background-color: $color-red;
        position: absolute;
        width: 0.25rem;
        height: 50%;
        left: -1px;
      }
    }
  }
}

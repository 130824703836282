@import '../../../../../assets/scss/settings/colors';

.info-action {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em;
}

.device-property {
  border-bottom-style: solid;
  border-bottom-width: 0.01em;
  border-bottom-color: $color-atlo;
}

.overview {
  &-btn-grey {
    width: 120px;
    height: 40px;
    margin-left: 0.8rem;
    border-radius: 20px;
    background-color: $color-white;
    border: solid 1px;
    border-color: $color-dusty-gray;
    color: $color-dusty-gray;
  }
}

.columns:not(:last-child).width-full {
  margin-bottom: 0rem;
  margin-top: 0.15em;
}

.column-border-bottom:not(:last-child) {
  border-bottom: solid 0.1rem;
  border-color: $color-wild-sand;
}

.tile.is-vertical > .tile.is-child:not(:last-child).flex-zero.margin-override {
  margin-bottom: 1rem !important;
}

.wifi-dashboards-hyperlink {
  fill: $color-red;
  height: 1rem;
  margin-left: 2px;
}
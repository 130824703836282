@import '../../../../../assets/scss/settings/colors';
@import '../../../../../assets/scss/settings/border-radius';

.favourites {
  height: 70vh;
  padding-right: 2px;
  overflow: hidden auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.25rem;
    border: 1px solid #e9e9e9;
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    outline: 1px solid #b5b5b5;
    border-radius: 0.25rem;
    height: 2rem;
  }

  &-item {
    border: 1px solid #dbdbdb;
    border-radius: 2.5rem;
    margin: .5rem .1rem;
    padding: 0rem 0.15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    button {
      padding: 0rem .5rem;
    }

    &.is-editing{
      border: 1px solid #3e9bac;
      box-shadow: 0 0 3px #3e9bac;
    }

    &.similar-search{
      background-color: #f4f4f4;
      border: 1px solid white;
    }

    &.similar-search *{
      cursor: default;
      background-color: #f4f4f4;
    }

    .is-editing{
      border: 0px;
      border-bottom: 1px solid #3e9bac;
      display: flex;
      flex-grow: 1;
      font-size: 15px;
      outline: none;
    }

    .icon-button {
      margin: 0rem;
      padding: 0rem;
      stroke: #4a4d4e;

      &.icon-green {
        stroke: green;
      }

      &:hover {
        transform: scale(1.1);
        stroke: black;
      }
    }

    &:hover,
    &:focus {
      background-color: $color-alabaster;
      cursor: pointer;

      .is-editing{
        background-color: $color-alabaster;
        cursor: pointer;
      }
    }

    > :last-child {
      text-align: right;
      padding-right: 0.25rem;
    }

    &:active{
      background-color: $color-white;
    }
  }

  .star {
    color: $color-lemon-yellow;
  }
}

.tabs ul li .button {
  &.button-link {
    margin: 0px !important;
    vertical-align: top;
    border-bottom: 1px solid white;
    padding: 1rem;
  }
}

.adv-search-icon {
  &.in-input {
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    z-index: 999;

    &:hover {
      transform: translateY(-50%) scale(1.1);
      cursor: pointer;
    }
  }
}

.column {
  &.low-priority{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 5;
  }
}
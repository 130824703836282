@import '../../assets/scss/settings/colors';

.simple-table-header {
  font-weight: bold;
  padding-bottom: .5em;
  user-select: none;

  .columns {
    margin: 0;
  }

  .column {
    padding: 1rem;
    display: flex;
    align-items: center;

    &.cursor-pointer {
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
      pointer-events: all;
    }
  }
}

.simple-table-body {
  .columns {
    margin: 0 !important;

    &.stripped {
      background-color: $color-wild-sand;
    }

    &:hover {
      font-weight: bold;

      .simple-table-label {
        font-weight: bold;
      }
    }
  }

  .column {
    padding: 1rem;
  }
}

.simple-table-with-checkbox-row {
  cursor: pointer;
}

.simple-table-header-label {
  font-weight: bold !important;
}
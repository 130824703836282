// margin ----------------------------------
.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.5rem;
}

.m-6 {
  margin: 3rem;
}

// margin-top ----------------------------

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.5rem;
}

.mt-6 {
  margin-top: 3rem;
}

// margin-bottom ---------------------------------
.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.5rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

// margin-top & margin-bottom ---------------------------------
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

// margin-right
.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.5rem;
}

.mr-6 {
  margin-right: 3rem;
}

// margin-left ------------------------------

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.5rem;
}

.ml-6 {
  margin-left: 3rem;
}

// margin-left & margin-right ---------------------------------
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-6 {
  margin-left: 3rem;
  margin-right: 3rem;
}

// margin auto ---------------------------------
.m-auto {
  margin: auto;
}

// margin-left auto ---------------------------------
.ml-auto {
  margin-left: auto;
}

// margin-right auto ---------------------------------
.mr-auto {
  margin-right: auto;
}

// margin left & right auto ---------------------------------
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

// margin-top auto ---------------------------------
.mt-auto {
  margin-top: auto;
}

// margin-base auto ---------------------------------
.mb-auto {
  margin-bottom: auto;
}

// margin top & base auto ---------------------------------
.mx-auto {
  margin-top: auto;
  margin-bottom: auto;
}

// padding auto ---------------------------------
.p-auto {
  padding: auto;
}

// padding-left auto ---------------------------------
.pl-auto {
  padding-left: auto;
}

// padding-right auto ---------------------------------
.pr-auto {
  padding-right: auto;
}

// padding-top auto ---------------------------------
.pt-auto {
  padding-top: auto;
}

// padding-base auto ---------------------------------
.pb-auto {
  padding-bottom: auto;
}

// padding ----------------------------------
.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rep;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.5rem;
}

.p-6 {
  padding: 3rem;
}

// padding-top ----------------------------

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.5rem;
}

.pt-6 {
  padding-top: 3rem;
}

// padding-bottom ---------------------------------
.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.5rem;
}

.pb-6 {
  padding-bottom: 3rem;
}

// padding-top & padding-bottom ---------------------------------
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

// padding-right
.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-4 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.5rem;
}

.pr-6 {
  padding-right: 3rem;
}

// padding-left ------------------------------

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-4 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.5rem;
}

.pl-6 {
  padding-left: 3rem;
}

// padding-left & padding-right ---------------------------------
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-6 {
  padding-left: 3rem;
  padding-right: 3rem;
}

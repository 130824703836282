@import '../../../../../assets/scss/settings/colors';

.topbar {
  &-container {
    background-color: $color-white;
    border-radius: 25px;
    align-items: center;
    padding: 0.6rem;
    display: inline-block;
  }
  &-container.has-margin-left-sm {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem !important;
  }
  &-item {
    padding: 0.3rem 1rem 0.3rem 1rem;

    &:not(:last-child) {
      border-right: solid 0.1rem;
      border-color: $color-aluminium;
    }
  }
}

.has-tooltip-topbar {
  position: relative;
  padding: 0.3rem 1rem 0.3rem 1rem !important;
}

.has-tooltip-topbar::after {
  background-color: $color-white;
  border-radius: 10px;
  color: $color-mine-shaft;
  display: none;
  padding: 5px;
  border: 1px solid $color-mine-shaft;
  position: absolute;
  text-align: center;
  line-height: normal;
  z-index: 99999;
}

.has-tooltip-topbar:hover::after {
  display: block;
  white-space: nowrap;
}

.has-tooltip-topbar:hover::before {
  display: block;
}

.has-tooltip-topbar.top-tooltip::after {
  content: attr(data-info);
  top: 15px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.has-tooltip-topbar.top-tooltip::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

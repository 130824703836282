.button-container {
  text-align: end;
}

.management-container {
  align-items: center;
  border-bottom: 0.1rem solid #f4f4f4;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}


@import '../../../../../../assets/scss/settings/colors';

.rtf-logs-history-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: .1rem solid $color-aluminium;

  .rtf-search-container {
    display: flex;
    align-items: center;
  }

  .field {
    margin: 0 2rem !important;
  }
}

.rtf-activity-logs-search-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;

  .activity-log-search-date-input {
    grid-column: span 2;
    margin: 1rem 0;
  }

  .activity-log-search-button {
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .button.is-larger.is-primary {
      margin: 1rem 0;
    }
  }
}

.activity-log-tasks-container {
  .panel {
    padding: 0;
  }
  
  .search-records-per-page {
    margin-bottom: 2rem !important;
  }
}

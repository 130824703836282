@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/colors';
@import '../../assets/scss/tools/placeholders';
@import '../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.category {
  display: flex;
  flex-direction: column;
  flex: 1;

  &-title {
    margin: 0 0.75rem 0.5rem 0.75rem !important;
    padding-bottom: 0.75rem;
    border-bottom: solid 1px $color-aluminium;
  }
}

.filters {
  transition: all 0.3s;

  &-expanded {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
  }

  &-collapsed {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }
}

.badges {
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  flex-grow: 5 !important;
}

.filter-badge {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding: 0 0.75rem;
  white-space: pre-wrap;
  border-radius: $border-rounded;

  &.is-secondary {
    background: $color-turquoise;
    color: $color-white;
  }

  .delete {
    margin-left: 0.25rem;
    margin-right: -0.375rem;
  }
}

.date-picker-input {
  .floating-label {
    top: -0.5rem !important;
    left: 0.75rem !important;
    font-size: 14px !important;
    opacity: 1 !important;
    
    .tooltip-circle {
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px;
    }
  }
}

.generic-input {
  .field {
    button {
      height: 40px;
      border-radius: .25rem;
    }
  }

  .tooltip-circle {
    cursor: pointer;
  }
}
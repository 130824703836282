.recharts-defaut-legend {
  width: fit-content;
  position: fixed;
  left: 0;
}

.recharts-legend-item:hover {
  cursor: pointer;
}

.recharts-tooltip-cursor {
  fill: none;
}

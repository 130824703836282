@import '../../assets/scss/settings/colors';
@import '../../assets/scss/settings/border-width';
@import '../../assets/scss/settings/border-radius';

.pagination {
  width: 100%;

  &-list {
    margin-bottom: 1rem;
  }

  &-action {
    background-color: transparent;
    border: none;
    color: $color-dove-gray;
    cursor: pointer;
    padding: 0.6rem 0;

    &-left {
      padding-left: 1.25rem;
    }

    &-right {
      padding-right: 1.25rem;
    }

    &:not(:disabled):hover {
      color: $color-abbey;
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }

    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &-label {
    border: $border-width-sm solid $color-nobel;
    border-radius: $border-radius-4;
    color: $color-dove-gray;
    padding: 0.85rem 1rem 0.75rem;
    margin: 0 1.2rem;
  }
}

.button {
  display: inline-block;
  height: auto;
  padding: 0.5rem 2.5rem;
  border-radius: $border-radius-6;
  @include transition(background, 0.2s, ease-in-out);

  & + & {
    margin-left: 1rem;
  }

  &.is-primary {
    color: $color-white;
    background-color: $primary;
    border: $border-width-sm solid $primary;

    &:hover {
      background-color: $color-maroon;
    }
  }

  &.is-primary-confirm {
    color: $color-white;
    background-color: $color-turquoise;
    border: $border-width-sm solid $color-turquoise;

    &:hover {
      background-color: $color-dark-turquoise;
    }
  }

  &.is-label-primary {
    color: $primary;
  }

  &.is-secondary {
    color: $color-white;
    background-color: $color-dove-gray;
    border: $border-width-sm solid $color-dove-gray;

    &:hover {
      background-color: $color-mine-shaft;
    }
  }

  &.has-icon {
    border-color: $color-dove-gray;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    svg {
      fill: $color-dove-gray;
      transition: transform 0.3s ease;
      vertical-align: middle;
    }
  }

  &.is-rounded {
    border-radius: $border-rounded;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &.is-circle {
    border-radius: $border-circle;
    display: inline-flex;
    padding: 5px;

    &.is-selected {
      border-color: $primary;

      svg {
        stroke: $primary;
      }
    }

    &.is-highlighted {
      border-color: $color-turquoise;
    }

    &.has-separator {
      margin-left: 33px;

      &::before {
        background-color: $color-silver;
        content: '';
        height: 1.75rem;
        position: absolute;
        right: 58px;
        width: 1px;
      }
    }

    svg {
      padding: 4px;
    }
  }

  &.is-larger {
    min-width: 120px;
  }

  &-link,
  &-action {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &-action {
    color: $color-turquoise;
    display: inherit;
    text-decoration: underline;

    &:hover,
    &.is-hovered {
      color: darken($color-turquoise, 5%);
    }

    &:focus,
    &:focus:not(:active).button {
      box-shadow: none;
    }
  }

  &-2cols {
    button {
      display: flex;
      margin-bottom: 20px;
      padding: 0.45rem;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  svg {
    &.icon:last-child:not(:first-child) {
      margin-left: 5px;
    }
  }

  .icon {
    height: 1rem;
    width: 1rem;
  }

  &-date-label {
    border-color: $color-dove-gray;
    color: $color-dove-gray;
    font-weight: 100;
    padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;

    .icon-svg {
      height: 1.25rem;
      position: absolute;
      right: 1rem;
      top: 0.625rem;
      width: 1.25rem;
    }
  }

  &:focus,
  &:focus:not(:active) {
    box-shadow: none;
  }
}

button {
  &.button {
    &:focus {
      box-shadow: none;
    }
  }
}

@media screen and (min-width: 450px) and (max-width: $tablet), (min-width: $fullhd) {
  .button {
    &-2cols {
      button {
        display: inline-block;
        margin-right: 20px;
        width: calc(50% - 10px);

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@import '../../../../../assets/scss/settings/colors';
@import '../../../../../assets/scss/tools/placeholders';
@import '../../../../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.channels {
  margin: 2.5em 1.5em;

  h3.text {
    margin-bottom: 3rem;
  }

  h3.text {
    margin-bottom: 3rem;
  }
}

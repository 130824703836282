@import '../../../../../../assets/scss/settings/colors';

.section-title {
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 1em;
}

.divider {
  content: '';
  margin-top: -25px;
  padding-bottom: 5px;
  border-top: 1px solid $color-silver
}

.status-column {
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
}
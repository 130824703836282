@import 'assets/scss/settings/_colors.scss';

.sidebar {
  &-sticky {
    position: sticky;
    top: 101px;
    bottom: 0;
    background-color: $color-white;
    width: 20vw;
    height: calc(100vh - 101px);
    transition: all 0.3s;
    border-left: solid 1px $color-white;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
  }

  &-content-collapsed {
    width: 2rem;

    > :last-child {
      opacity: 0;
    }
  }

  &-column-border-bottom {
    border-bottom: 1px solid $color-aluminium;
  }

  &-button {
    background-color: $color-red;
    border: solid 0.1rem $color-red;
    border-radius: 7rem;
    width: 1.5rem;
    height: 1.5rem;
    position: sticky;
    z-index: 1;
    cursor: pointer;
    margin-left: -0.75rem;
    top: 9.063rem;

    svg {
      transition: transform 0.6s;
    }

    &:hover + .sidebar-sticky {
      border-left: solid 1px $color-red;
    }
  }
}

.vcolumn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    display: flex;
    align-items: flex-start;
    flex: 1;
    max-width: 50%;
    word-break: break-all;
    flex-wrap: wrap;

    &:first-child {
      margin-right: 0.5rem;
    }

    > h6,
    > p {
      font-size: 14px;
    }

    > div {
      > p {
        font-size: 14px;
      }
    }
  }
}

.left-side {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph;

  .sidebar-sticky > * {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph;
    filter: fliph;
  }
}

.modal {
  &-content {
    border-radius: $border-radius-6;
  }

  .card,
  .badge {
    animation: fadeIn 0.5s ease-in-out;
  }

  .card {
    max-height: calc(100vh - 10rem);
    overflow-y: auto;
    padding: 2rem;

    &-wrapper {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 620px;

      &.is-small {
        width: 370px;
      }

      &.is-medium {
        width: 450px;
      }

      &.is-large {
        width: 800px;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
  }

  &-upload {
    background-color: $color-alabaster;
    border: $border-width-sm dashed $color-silver;
    padding: 2rem;
    text-align: center;

    button {
      margin: 1.5rem 0;
    }

    p {
      font-family: $font-family-vodafone-light;
      letter-spacing: 0.5px;

      & + p {
        margin-top: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .modal {
    .card {
      max-width: 472px;
      width: 85%;
    }

    &-buttons {
      flex-direction: column;

      button:not(:first-child) {
        margin-left: 0;
        margin-top: 0.75rem;
      }
    }

    &-upload {
      padding: 1rem;
    }
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 75vh;
  }
}

@keyframes scale {
  from {
    transform: scale(0);
    max-height: 0;
    width: 0;
  }
  to {
    transform: scale(1);
    max-height: 600px;
    width: 100%;
  }
}

@import '../../assets/scss/settings/colors';

.tooltip {
  &-circle {
    width: 1.15rem;
    height: 1.1rem;
    border-radius: 1.5rem;
    background: $color-turquoise;
    position: relative;
    opacity: 0.4;

    svg {
      width: 1.1rem;
      height: 1.1rem;
    }

    &:hover + .tooltip-container,
    &:hover + .tooltip-container-table {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.4s, visibility 0.4s;
    }
  }

  &-container {
    visibility: hidden;
    position: absolute;
    width: 250px;
    background-color: #666666fa;
    color: $color-white;
    text-align: center;
    padding: 0.5rem;
    border-radius: 6px;
    z-index: 10;
    top: 140%;
    left: 50%;
    margin-left: -125px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.4s, visibility 0.4s;

    &::before {
      content: ' ';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-dove-gray transparent;
    }

    &.tooltip-container-table {
      width: 350px;
      margin-left: -175px;
    }
  }
}

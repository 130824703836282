.is-centered {
  text-align: center;
}

.fields-container {
  padding-bottom: 2em;
}

.field-row {
  padding-bottom: 1em;
}
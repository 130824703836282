@import '../../assets/scss/settings/animations';
@import '../../assets/scss/settings/border-radius';
@import '../../assets/scss/settings/colors';
@import '../../assets/scss/tools/placeholders';
@import '../../assets/scss/settings/border-width';
@import '../../assets/scss/helpers/align';

.table-optimized {
  background-color: $color-white;
  border-radius: $border-radius-10;
  @extend %tile-boxshadow;
  font-size: 1rem;
  font-weight: 100;
  margin-bottom: 2rem;

  .flex {
    &-table {
      display: flex;
      position: relative;

      &.header {
        font-weight: bold;
        user-select: none;

        &.has-action {
          padding-right: 3.35rem;

          .flex-cell {
            &:last-child {
              padding-right: 0.75rem;
            }
          }
        }

        &.has-status {
          padding-left: 2.5rem;

          .flex-cell {
            padding: 1rem;
            padding-left: 0rem;

            &:first-child {
              padding-left: 0.75rem;
            }
          }
        }
      }

      &.row {
        &.clickable {
          cursor: pointer;
          color: inherit;
          &.default {
            cursor: default !important;
          }
        }
        &.clickable:hover {
          font-weight: bold;
        }

        &.is-active {
          .flex-cell,
          .flex-table-button {
            background-color: rgba(0, 124, 146, 0.15) !important;
          }
        }

        &:nth-child(even) {
          .flex-cell,
          .flex-table-button {
            background-color: $color-wild-sand;
          }
        }

        &:last-child {
          border-radius: $border-radius-0 $border-radius-0 $border-radius-10 $border-radius-10;

          &.is-active::before {
            border-radius: 0 0.5rem 0 0;
          }
        }

        .is-danger {
          background-color: $primary !important;
          color: $color-white;
          font-weight: bold;
        }

        .has-status {
          align-items: center;
          display: flex;
          flex: none;
          padding-left: 1rem;

          .is-status {
            border-radius: $border-circle;
            display: inline-block;
            height: 0.9rem;
            width: 0.9rem;

            &.is-success {
              background-color: $color-ui-green;
            }

            &.is-info {
              background-color: $color-aqua-blue;
            }

            &.is-warning {
              background-color: $color-lemon-yellow;
            }

            &.is-danger {
              background-color: $primary;
            }

            &.is-default {
              background-color: $color-abbey;
            }
          }
        }
      }

      .has-status {
        min-width: 2.5rem;
      }

      &-button {
        width: 5%;
        text-align: right;
        padding-right: 20px;
        display: inline-block;
      }
    }

    &-cell {
      align-items: center;
      display: flex;
      flex: 1;
      overflow-wrap: break-word;
      padding: 1rem 0.75rem;
      word-wrap: break-word;
      word-break: break-word;

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }

      &.flex-none {
        flex: none;
      }

      & button[disabled] {
        background: none;
      }

      &.flex-cell {
        &-action {
          flex: 0;
          padding: 0.75rem;
          min-width: 3.35rem;

          .icon-svg {
            height: 2rem;
            width: 2rem;
          }
        }
      }
    }
  }
  &-horizontal-container-scroll {
    overflow-x: auto;
    width: auto;
  }

  .table-checkbox {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.table-prettier {
  font-size: 1rem;
  font-weight: 100;

  .flex {
    &-table {
      display: flex;

      &.header {
        font-weight: bold;

        &.has-action {
          padding-right: 3.35rem;
          margin-right: 20px;

          .flex-cell {
            &:last-child {
              padding-right: 0.75rem;
            }
          }
        }

        &.has-status {
          padding-left: 2.5rem;

          .flex-cell {
            padding: 1.5rem;
            padding-left: 0rem;

            &:first-child {
              padding-left: 0.75rem;
            }
          }
        }
      }

      &.row {
        background-color: white;
        border-radius: $border-radius-5;
        margin-bottom: 4px;

        .is-danger {
          background-color: $primary;
          color: $color-white;
          font-weight: bold;
        }

        .has-status {
          align-items: center;
          display: flex;
          flex: none;
          padding-left: 1rem;

          .is-status {
            border-radius: $border-circle;
            display: inline-block;
            height: 0.9rem;
            width: 0.9rem;

            &.is-success {
              background-color: $color-ui-green;
            }

            &.is-info {
              background-color: $color-aqua-blue;
            }

            &.is-warning {
              background-color: $color-lemon-yellow;
            }

            &.is-danger {
              background-color: $primary;
            }

            &.is-default {
              background-color: $color-abbey;
            }
          }
        }
      }

      .has-status {
        min-width: 2.5rem;
      }
    }

    &-cell {
      align-items: center;
      display: flex;
      flex: 1;
      overflow-wrap: break-word;
      padding: 1.5rem 0.75rem;
      word-wrap: break-word;
      word-break: break-word;
      &:first-child {
        padding-left: 32px;
      }

      &:last-child {
        padding-right: 32px;
      }

      &.flex-cell {
        &-action {
          flex: 0;
          padding: 0.75rem;
          margin-right: 20px;
          position: relative;

          .actions {
            cursor: pointer;
            display: inherit;

            &:hover {
              > .action-list {
                display: block;
              }
            }
          }

          .action-list {
            display: none;
            min-width: 9rem;
            position: absolute;
            right: 0;
            transition: opacity 0.3s ease-in-out;
            z-index: 100;

            li {
              color: $color-mine-shaft;
              cursor: pointer;
              display: flex;

              &:first-child {
                button {
                  border-radius: $border-radius-5 $border-radius-5 $border-radius-0 $border-radius-0;
                }
              }

              &:last-child {
                button {
                  border-radius: $border-radius-0 $border-radius-0 $border-radius-5 $border-radius-5;
                }
              }

              button {
                justify-content: flex-start;
                padding: 0.675rem 1rem;
                width: 100%;

                &:not(:disabled):hover {
                  background-color: $color-wild-sand;
                }
              }
            }
          }
        }
      }
    }
  }
}

.no-data {
  margin: auto;
  animation: scale 0.6s;
  max-height: 600px;

  svg {
    width: 270px;
  }
}

.inputText {
  width: 100% !important;
  height: 40px !important;
}

.clickable-icon {
  cursor: pointer;

  .icon {
    vertical-align: text-bottom;
  }
}

.tableHeader-tooltip {
  height: 20px;
  width: 20%;
  &-wrap {
    position: absolute;
    pointer-events: visible;
    padding-left: 0.2rem;
  }
  .tooltip-container::before {
    left: 92%;
  }
  .tooltip-container {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .table-optimized {
    .responsive-title {
      display: inline-block;
    }

    .search-records-per-page {
      display: inline-block;
      float: right;
    }

    .clickable {
      float: inherit;
    }
  }
}

.white-space {
  &-pre {
    white-space: pre-wrap;
  }
}

.div-tooltip {
  all: unset;
}
.has-tooltip::after {
  background-color: $color-white;
  border-radius: 10px;
  color: $color-mine-shaft;
  display: none;
  padding: 5px;
  border: 1px solid $color-mine-shaft;
  position: absolute;
  text-align: center;
  line-height: normal;
  z-index: 99999;
}

.has-tooltip:hover::after {
  display: block;
}

.has-tooltip:hover::before {
  display: block;
}

.has-tooltip.top-tooltip::after {
  content: attr(data-info);
  top: 25px;
  left: 85%;
  transform: translate(-50%, calc(-100% - 10px));
}

.has-tooltip.top-tooltip::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

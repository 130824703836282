@import '../../../../assets/scss/settings/colors';
@import '../../../../assets/scss/tools/placeholders';
@import '../../../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.homepage {
  margin: 2.5em 1.5em;

  h3.text {
    margin-bottom: 3rem;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 48px);
    margin: 0 -24px;
  }

  &-box {
    width: calc(25% - 48px);
    min-width: 264px;
    margin: 0 24px 48px;
    color: $color-mine-shaft;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 2rem;
    @extend %tile-boxshadow;
    background-color: $color-white;
    border: solid 1px transparent;
    border-radius: 8px;
    text-align: center;
    transition: border 0.3s;

    svg {
      width: 64px;
      height: 64px;
      margin-bottom: 24px;
    }

    &:hover {
      border-color: $color-turquoise;
    }

    p {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .homepage {
    &-container {
      width: calc(100% + 24px);
      margin: 0 -12px;
    }
    &-box {
      width: calc(50% - 24px);
      margin: 0 12px 24px;
    }
  }
}

@media screen and (max-width: 450px) {
  .homepage {
    &-box {
      width: 100%;
    }
  }
}

.sidemenu {
  &-container {
    &.tile {
      &.is-vertical {
        flex: none;
        width: 20%;
      }
    }
  }
}

.actions {
  &-expanded svg {
    height: 1.25rem;
    width: 1.25rem;
    transform: rotate(180deg);
    transition: transform 0.4s;
  }

  &-collapsed svg {
    height: 1.25rem;
    width: 1.25rem;
    transform: rotate(0deg);
    transition: transform 0.4s;
  }
}

@import '../../../../../../assets/scss/settings/colors';

.scan {
  &-popover {
    position: absolute;
    top: 100%;
    box-shadow: 0 1px 2px rgba($color-black, 0.2);
    z-index: 100;
    width: 20rem;
    visibility: hidden;
  }

  &-add-freq {
    &:hover > .scan-popover {
      visibility: visible;
    }
  }
}

.loader,
.loader:after {
  border-radius: $border-circle;
  width: 5rem;
  height: 5rem;
}

.loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(0, 0, 0, 0.05);
  border-right: 0.5em solid rgba(0, 0, 0, 0.05);
  border-bottom: 0.5em solid rgba(0, 0, 0, 0.05);
  border-left: 0.6em solid $color-red;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1s infinite linear;
  animation: load8 1s infinite linear;

  &-wrapper {
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-container {
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 200;

    .loader-wrapper {
      height: 100% !important; //remove this important once speed-test scss has been refactored
    }

    &.is-active {
      display: block;
    }
  }

  &.secondary {
    border-left: 0.3em solid $color-abbey;
  }
}

@import '../../../../../assets/scss/settings/colors';
@import '../../../../../assets/scss/tools/placeholders';
@import '../../../../../../node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../../../../../assets/scss/helpers/_spacing.scss';

div.tabs ul.is-marginless li.is-active button.button-link {
  color: $primary;
  padding: 1rem 38%;
}

div.tabs ul.is-marginless li button.button-link {
  padding: 1rem 38%;
}

#card-regionalization {
  height: 1136px;
}

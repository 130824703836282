.icon-svg {
  &.icon-middle {
    vertical-align: middle;
  }

  &.icon-red {
    stroke: red;
    stroke-width: 1rem;
  }
}

.columns {
  &.accordion-tab {
    line-height: 2.5rem;
  }
  .is-flex-grow-0 {
    flex-grow: 0;
  }

  span {
    .border-right {
      border-right: 1px solid #a3a3a3;
      padding: 0.5rem;
      margin-right: 1rem;
    }
  }
}

.accordion-box {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem !important;
  box-shadow: none !important;

  .table-optimized {
    border-radius: 5px;
  }

  .table-optimized-horizontal-container-scroll {
    &::-webkit-scrollbar {
      width: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid #e9e9e9;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e8e8e8;
      outline: 1px solid #e8e8e8;
      border-radius: 0.25rem;
      height: 1rem;
    }
  }

  .is-clickable {
    cursor: pointer;
    padding-left: 0;

    svg {
      transition: transform 0.6s;
    }

    .icon-90 {
      transform: rotate(90deg);
    }
  }

  hr {
    height: 0.5px;
  }

  .table-accordion {
    transition: all 0.3s;

    &-expanded {
      visibility: visible;
      opacity: 1;
      max-height: 1000px;
    }

    &-collapsed {
      visibility: hidden;
      opacity: 0;
      max-height: 0;
    }

    &.border-top {
      border-top: 1px solid #a3a3a3;
    }
  }
}

@import '../../..//assets/scss/settings/colors';

.box-card-is-link {
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $secondary;

    .icon,
    .subtitle {
      color: $color-white;
    }

    svg {
      stroke: $color-white;
    }

    .fill-on-hover {
      fill: $color-white;
    }
  }
}

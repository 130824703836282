@import '../../../assets/scss/settings/border-radius';
@import '../../../assets/scss/settings/border-width';
@import '../../../assets/scss/settings/colors';

.input-wrap {
  position: relative;
  width: 100%;

  .input-text {
    width: 100%;
    height: 2.5rem;
    outline: none;
    border: 1px solid $color-dove-gray;
    font-size: 16px;
    border-radius: 0.25rem;
    padding: 0.25rem 0.9rem;
    color: $color-mine-shaft;
    position: relative;

    &:focus {
      border-color: $color-turquoise;

      & ~ .floating-label {
        color: $color-turquoise;
      }
    }

    &:focus ~ .floating-label,
    &:not(:placeholder-shown) ~ .floating-label {
      top: -0.5rem;
      left: 0.75rem;
      font-size: 14px;
      opacity: 1;
      background: $color-white;

      .tooltip-circle {
        width: 1rem;
        height: 1rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    top: 0.5rem;
    left: 0.75rem;
    transition: 0.2s ease all;
    padding: 0 0.25rem;
    color: $color-dove-gray;
  }

  &-tooltip {
    position: absolute;
    pointer-events: visible;
    background: $color-white;
    padding-right: 0.4rem;
  }
}

.is {
  &-align {
    &-end {
      align-items: flex-end;
    }
    &-start {
      align-items: flex-start;
    }
  }
  &-vertical {
    &-center {
      display: flex;
      align-items: center;
    }
  }
}

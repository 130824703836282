.no-drop-shadow {
  & > div {
    box-shadow: none;
  }
}
.flex-topbar {
    display: flex;
    padding: 20px;

    & > h4 {
        margin-bottom: 0!important;
    }
}
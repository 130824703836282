@import '../../../assets/scss/settings/colors';

.table-container {
  display: flex;
  justify-content: space-between;
}

.table-item {
  width: 100%;
  padding: 0 0.5em;
}

.table-header-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-centered {
  vertical-align: middle !important;
}

.button-content {
  display: flex !important;
}

.container {
  background-color: $color-white;
}

.table-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  padding: 0.75em 0;
}

.no-entries-container {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
}
.column {
  &-rounded {
    & :first-child {
      border-top-right-radius: $border-radius-0;
      border-bottom-right-radius: $border-radius-0;
    }

    & :last-child {
      border-top-left-radius: $border-radius-0;
      border-bottom-left-radius: $border-radius-0;
    }

    & :not(:first-child):not(:last-child) {
      border-radius: $border-radius-0;
    }
  }

  &-squared {
    & :first-child {
      border-radius: $border-radius-0;
    }

    & :last-child {
      border-radius: $border-radius-0;
    }

    & :not(:first-child, :last-child) {
      border-radius: $border-radius-0;
    }
  }

  &-bottom {
    bottom: 1.25rem;
    left: 0.75rem;
    position: absolute;
    width: 100%;

    .column {
      &:first-child {
        padding: 1.25rem 0.75rem 0.75rem 1.25rem;
      }

      &:last-child {
        padding: 1.25rem 1.25rem 0.75rem 0.75rem;
      }
    }
  }

  &-disabled {
    opacity: 0.25;
  }

  .is-right {
    display: flex;
    justify-content: flex-end;
    padding-left: 1rem;
  }

  &.is-narrow {
    flex: none;
  }
}

.columns {
  &.is-right {
    justify-content: flex-end;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.no-padding {
    .column {
      padding-top: 0;
      padding-bottom: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.accordion {
  margin-bottom: 2rem;

  &-item {
    background-color: $color-white;
    border-radius: $border-radius-6;
    box-shadow: 0 2px 4px rgba($color-black, 0.2);
    margin: 0 0 0.75rem;

    &:last-child {
      margin: 0;
    }

    &-collapsed {
      .accordion-content {
        display: none;
      }

      .accordion-chevron {
        svg {
          transform: rotate(0deg);
        }
      }
    }

    .table-optimized {
      margin-bottom: 0;
    }
  }

  &-heading {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin: 0;
    padding: 1.75rem 2rem;
    text-align: left;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &-content {
    margin: 0;
    padding: 0 1.5rem 2rem;
    position: relative;

    &:before {
      content: '';
      background-color: $color-silver;
      width: 100%;
      height: 1px;
      display: block;
      margin-bottom: 20px;
    }
  }

  &-chevron {
    display: inline-block;
    padding-left: 1.25rem;

    svg {
      height: 1.25rem;
      stroke: $primary;
      transform: rotate(180deg);
      transition: transform 0.4s;
      width: 1.25rem;
    }
  }

  .chevron {
    display: table;
    position: relative;
    width: 100%;

    &-text,
    &-container {
      display: table-cell;
      vertical-align: middle;
    }

    &-container {
      line-height: 0;
      text-align: right;
    }
  }
}

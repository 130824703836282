@import '../../assets/scss/settings/colors';

.pagination-container {
  display: grid;
  grid-template-columns: 1fr 1fr 40% 1fr 1fr;
  text-align: center;
  user-select: none;
  
  .local-pagination-button {
    cursor: pointer;
    margin: 2px 10px 0 10px;

    svg {
      fill: $color-dusty-gray;
      height: 2rem;
    }

    &.active {
      &:hover {
        svg {
          fill: $color-black;
        }
      }
    }

    &.disabled {
      opacity: .3;
    }
  }
}

.pagination-indicators-container {
  display: flex !important;
  justify-content: center;

  div {
    display: flex;
  }
}

.page-indicator {
  width: 2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }

  &.reticence {
    cursor: default;
    &:hover {
      font-weight: normal;
    }
  }

  &.current {
    font-weight: bold;
    background-color: $color-red;
    color: $color-white;
    border: solid 1px red;
    border-radius: .5rem;
    font-size: 1.2rem;
  }
}
.tabs {
  display: flex;
  overflow-x: auto;
  width: 100%;

  & .button-link {
    align-items: center;
    color: $color-dove-gray;
    display: flex;
    justify-content: center;
    margin: 10px 0;
    padding: 1rem 1.25rem;

    &:hover,
    &:focus {
      color: $color-mine-shaft;
    }
  }

  ul {
    border-bottom-color: transparent;

    li.is-active .button-link {
      color: $color-mine-shaft;
      font-family: $font-family-vodafone-regular-bold;
      border-bottom: $border-width-sm solid $color-red;
    }
  }

  &.is-medium button {
    font-size: 1rem;
  }

  &.is-flex {
    ul li {
      flex: 1;
    }

    a {
      padding: 0.3rem 0;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: $widescreen) {
  .tabs {
    overflow-x: hidden;

    .button-link {
      padding: 1rem 1.2rem;
    }
  }
}

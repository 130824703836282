.tableCollisions {
  white-space: nowrap;

  & td {
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
  }

  & .selectedRow {
    background-color: rgb(230, 255, 237) !important;
  }

  & .headcol {
    position: absolute;
    width: 2em;
    left: 0;
  }
}

.section {
  padding: 1rem;

  &-spaced {
    margin: 2.5rem 1.5rem 2rem;
  }
}

@media screen and (min-width: $widescreen) {
  .section {
    padding: 1.5rem;
  }
}

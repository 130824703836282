.is {
  &-justified {
    &-center {
      justify-content: center;
    }

    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-between {
      justify-content: space-between;
    }

    &-around {
      justify-content: space-around;
    }
  }
}
